import { af } from 'date-fns/locale'

export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const LONG_ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = true
export const BLOWFISH_KEY = '7$7qhyq4kb9U$5nS2DWeK#GR7&632Y'
export const BLOWFISH_IV = 'Weafp#C4'
export const ENABLE_ARCHIVED_GAMES = true
export const DATE_LOCALE = af
export const WELCOME_CREDITS_MODAL_MS = 350
export const MILLENNIUM_MODAL_MS = 350
export const DAY_OF_WEEK_TO_SHOW_CREDITS = 5
export const SNOWFALL_DAY = '1900-12-25'
