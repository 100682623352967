import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const MadrigaalSangersModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="Advertensiebreek"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
        Ek gebruik nie 🥕 Wortel om dinge te bemark nie. Wel, nie tot nou nie.
        Ek het baie belangstellings. Ek is verslaaf aan my 💻rekenaar, ek
        hardloop 🏃‍♂️Comrades en ek sing in 'n 🎼sanggroep.
      </p>
      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
        Ons voer alles op van sielsverwekkende stukke uit die Middeleeue tot
        bekende wysies wat jy dalk van die radio sal herken. Dink daaraan as 'n
        lewendige klankbaan van emosies wat jy nog moet ontdek!
      </p>
      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
        Bring 'n vriend, raak gemaklik, en kyk wat doen die 🥕 Wortel-skepper as
        hy nie andersins in die woordeboek vir 5-letter woorde soek nie.
      </p>
      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
        Sondag, 24 November om 16:00
        <br />
        PJ Olivier Kunssentrum, Stellenbosch
        <br />
        Kaartjies: R130{' '}
        <a
          href="https://qkt.io/b8UC26"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          https://qkt.io/b8UC26
        </a>
      </p>
      <div className="mt-2 rounded-xl bg-white p-3">
        <a href="madrigaalsangers.jpg" target="_blank" rel="noreferrer">
          <img
            className="rounded-xl"
            src="madrigaalsangers.jpg"
            alt="Die Stellenbosse Wortelsangers"
          />
        </a>
      </div>
    </BaseModal>
  )
}
