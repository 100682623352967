export const GAME_TITLE = '🥕 Wortel'

export const WIN_MESSAGES = [
  'Aangrypend!',
  'Aanskoulik!',
  'Asembenemend!',
  'Asemrowend!',
  'Bakgat!',
  'Besonders!',
  'Bewonderenswaardig!',
  'Briljant!',
  'Buitengewoon!',
  'Deftig!',
  'Eerbiedwekkend!',
  'Eersteklas!',
  'Eersterangs!',
  'Fantasties!',
  'Formidabel!',
  'Fraai!',
  'Gedug!',
  'Goed so!',
  'Grandioos!',
  'Groots!',
  'Hoëgraads!',
  'Hoogstaande!',
  'Imponerend!',
  'Indrukwekkend!',
  'Knap!',
  'Kolossaal!',
  'Kwaailappies!',
  'Magistraal!',
  'Majestueus!',
  'Manjifiek!',
  'Meesterlik!',
  'Merkwaardig!',
  'Monumentaal!',
  'Netjies!',
  'Ongeëwenaar!',
  'Ongelooflik!',
  'Onoortreflik!',
  'Onverbeterbaar!',
  'Onverbeterlik!',
  'Perfek!',
  'Poenankies!',
  'Pragtig!',
  'Prima!',
  'Prysenswaardig!',
  'Puik!',
  'Salig!',
  'Sensasioneel!',
  'Sierlik!',
  'Skitterend!',
  'Skouspelagtig!',
  'Spiekeries!',
  'Statig!',
  'Subliem!',
  'Super!',
  'Superieur!',
  'Swierig!',
  'Talentvol!',
  'Treffend!',
  'Uitmuntend!',
  'Uitsonderlik!',
  'Uitstaande!',
  'Vernuftig!',
  'Verruklik!',
  'Verstommend!',
  'Voortreflik!',
  'Voortreflik!',
  'Vorstelik!',
  'Weergaloos!',
  'Wonderbaarlik!',

  // Kaaps
  'Agtermekaar!',
  'Duidelik!',
  'Gevaarlik!',
  'Giftig!',
  'Kwaai!',
  'Nxa!',
  'Toedoe!',
  'Yster!',
]
export const LAST_GUESS_MESSAGES = [
  'Net-net!',
  'Skrams!',
  'Rakelings!',
  'Vel-af!',
  'Hittete!',
]
export const LOST_MESSAGES = ['Ontwortel! 🥀']
export const GAME_COPIED_MESSAGE = '🥕 Wortel-uitslae na die knipbord gekopieer'
export const NOT_ENOUGH_LETTERS_MESSAGE = "Plaas 'n letter in elke blokkie."
export const WORD_NOT_FOUND_MESSAGE = 'Aikona, die woord is nie in ons lys nie!'
export const HARD_MODE_ALERT_MESSAGE =
  'Die moeilike metode kan slegs aan die begin van die daaglikse spel geaktiveer word.'
export const HARD_MODE_DESCRIPTION =
  'Jy móét die gegewe leidrade in daaropvolgende raaiskote gebruik.'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Vir verbeterde kleurvisie'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Jy is ontwortel! Die korrekte woord was ${solution}.`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Jy móét 'n ${guess} in posisie ${position} gebruik.`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Jou raaiskoot móét 'n ${letter} bevat.`
export const ENTER_TEXT = 'Voer in ⏎'
export const DELETE_TEXT = 'Skrap ⌫'
export const STATISTICS_TITLE = 'Jou 🥕 Wortel-statistiek'
export const GUESS_DISTRIBUTION_TEXT = 'Suksesverdeling'
export const NEW_WORD_TEXT = 'Volgende spel'
export const SHARE_TEXT = 'Deel'
export const SHARE_FAILURE_TEXT =
  'Kan nie die resultate deel nie. Hierdie funksie is slegs in veilige kontekste (HTTPS) en in sommige of alle ondersteunende webblaaiers beskikbaar.'
export const MIGRATE_BUTTON_TEXT = 'Dra oor'
export const MIGRATE_DESCRIPTION_TEXT =
  "Klik hier om jou statistiek na 'n ander toestel oor te dra."
export const TOTAL_TRIES_TEXT = 'Potte gespeel'
export const SUCCESS_RATE_TEXT = 'Sukses' + String.fromCharCode(173) + 'koers' // 173 = soft hyphen
export const CURRENT_STREAK_TEXT = 'Huidige lopie'
export const BEST_STREAK_TEXT = 'Beste lopie'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "Jy gebruik 'n ingeboude webblaaier binne 'n ander toepassing en mag dalk probleme ervaar om jou uitslae te deel. Ons moedig jou aan om eerder jou toestel se standaardwebblaaier te gebruik."

export const DATEPICKER_TITLE = "Speel toeka s'n"
export const DATEPICKER_CHOOSE_TEXT = 'Kies'
export const DATEPICKER_TODAY_TEXT = 'vandag'
export const ARCHIVE_GAMEDATE_TEXT = 'Speldatum'
